export const setItemWithExpiry = (key, value, expiryInHours) => {
    const now = new Date();
    const expiryTime = now.getTime() + (expiryInHours * 60 * 60 * 1000);
    
    const item = {
        value: value,
        expiry: expiryTime,
    };

    localStorage.setItem(key, JSON.stringify(item));
};

// Function to get an item and check if it has expired
export const getItemWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        window.location.href = "/";
        return null;
    }

    return item.value;
};