import { useState } from "react";
import axios from "axios";
import { getItemWithExpiry } from "./utility";

const useApiRequest = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const makeRequest = async (url, method = "GET", requestData = null) => {
    setLoading(true);
    setError(null);

    try {
      const config = {
        method,
        url,
        baseURL: process.env.REACT_APP_BASE_URL,
        responseType: "json",
      };

      // Add Bearer token if it exists
      const token = getItemWithExpiry("token");
      if (token) {
        config.headers = {
          Authorization: `Bearer ${token}`,
        };
      }

      if (method === "GET") {
        config.params = requestData;
      } else {
        config.data = requestData;
      }

      const response = await axios(config);
      setData(response.data.data);
    } catch (err) {
      const status = err.response ? err.response.status : 500;
      let errorMessage = "An unexpected error occurred.";
      
      if (status === 500) {
        errorMessage = "Server error. Please try again later.";
      } else if (status === 400) {
        errorMessage = "Bad request. Please check your input.";
      } else if (status === 404) {
        errorMessage = "Resource not found.";
      }
      
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, makeRequest };
};

export default useApiRequest;
