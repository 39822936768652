import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Form } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import LocationComponent from "../LocationComponent/LocationComponent";
import useApiRequest from "../../../helper/useApiHook";

const EditStadiumModal = ({ showModal, editStadiumModal, stadium }) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [formPayload, setFormPayload] = useState({
        venue: '',
        totalRating: 5,
        country: '',
        countryCode: '',
        coordinates: { lat: -19.8953399, lng: -44.0487671 },
        _id: '',
        stadium_id: ''
    });

    const { makeRequest: editStadiumRequest } = useApiRequest();

    useEffect(() => {
        if (showModal) {
            setModal(true);
            setFormPayload({
                stadium_id: stadium._id,
                coordinates: stadium.coordinates,
                venue: stadium.name
            });
            form.setFieldsValue({
                venue: stadium.name,
                lat: stadium.coordinates.lat,
                lng: stadium.coordinates.lng
            });
        }
    }, [showModal, stadium, form]);

    const toggleModal = () => {
        setModal(false);
        editStadiumModal();
    };

    const getLocation = (event) => {
        const { lat, lng } = event;
        setFormPayload(prev => ({
            ...prev,
            coordinates: { lat, lng }
        }));
        form.setFieldsValue({ lat, lng }); // Update form inputs
    };

    const onFinish = (values) => {
        const payload = {
            venue: values.venue,
            stadium_id: formPayload.stadium_id,
            coordinates: {
                lat: parseFloat(values.lat), // Convert to float
                lng: parseFloat(values.lng) 
            }
        };
        
        setIsLoading(true);

        editStadiumRequest("edit-stadium", "PUT", payload)
            .then(() => {
                toggleModal();
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error:", error);
                setIsLoading(false);
            });
    };

    return (
        <Modal
            title="Edit Details"
            visible={modal}
            onOk={toggleModal}
            onCancel={toggleModal}
            footer={false}
        >
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={{ venue: formPayload.venue, lat: formPayload.coordinates.lat, lng: formPayload.coordinates.lng }}
            >
                <Form.Item label="Venue:" name="venue">
                    <Input />
                </Form.Item>
                <Form.Item label="Latitude:" name="lat" rules={[{ required: true, message: 'Please input the latitude!' }]}>
                    <Input type="number" step="any" />
                </Form.Item>
                <Form.Item label="Longitude:" name="lng" rules={[{ required: true, message: 'Please input the longitude!' }]}>
                    <Input type="number" step="any" />
                </Form.Item>
                <Form.Item label="Location Search:">
                    <LocationComponent
                        address={formPayload.venue}
                        lat={formPayload.coordinates.lat}
                        lng={formPayload.coordinates.lng}
                        getlocation={getLocation}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditStadiumModal;
