import axios from "axios";
import { getItemWithExpiry } from "./utility";

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: "json",
});

api.interceptors.request.use(
  (config) => {
    const token = getItemWithExpiry("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
