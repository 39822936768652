import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Table, Button } from "antd";
import useApiRequest from "../../helper/useApiHook";
import { showErrorToast } from "../../helper/toaster";

const columns = [
  {
    title: "Title",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Lat",
    dataIndex: "latitude",
    key: "latitude",
  },
  {
    title: "Lng",
    dataIndex: "longitude",
    key: "longitude",
  },
];

const CreateAttractionModal = ({ showModal, hideModal, stadium }) => {
  const [prompt, setPrompt] = useState("");
  const [rawData, setRawData] = useState([]);
  const [attractionDataFromDb, setAttractionDataFromDb] = useState();

  const { data: attractionData, loading: loadingFetch, error: fetchError, makeRequest: fetchAttractionData } = useApiRequest();
  const { data: attractionDataCreated, error: createError, makeRequest: fetchAttractionDataCreate } = useApiRequest();

  const handleViewFetchData = async () => {
    if (!prompt) {
      showErrorToast("Please enter a prompt before viewing created data.");
      return;
    }
    
    await fetchAttractionData("/attractions-with-custom-prompt-fetch", "POST", {
      venueCountry: stadium.country,
      venueId: stadium.id,
      prompt,
    });
  };

  const handleViewCreatedData = async () => {
    if (!attractionDataFromDb || attractionDataFromDb.length === 0) {
      showErrorToast("Please fetch attractions before creating in DB.");
      return;
    }
    
    await fetchAttractionDataCreate("/attractions-with-custom-prompt-create", "POST", {
      data: attractionDataFromDb,
    });
  };

  useEffect(() => {
    if (fetchError) {
      showErrorToast(fetchError);
    }
  }, [fetchError]);

  useEffect(() => {
    if (createError) {
      showErrorToast(createError);
    }
  }, [createError]);

  useEffect(() => {
    if (attractionData) {
      setRawData(attractionData.rawData);
      setAttractionDataFromDb(attractionData.flattenedAttractions);
    }
  }, [attractionData]);

  useEffect(() => {
    if (attractionDataCreated) {
      hideModal();
    }
  }, [attractionDataCreated]);

  return (
    <Modal
      visible={showModal}
      onCancel={hideModal}
      width={800}
      footer={false}
    >
      <Row gutter={16} style={{ marginBottom: "20px" }}>
        <Col span={8}>
          <div>
            <strong>Stadium Name:</strong>
            <p>{stadium.name}</p>
          </div>
        </Col>
        <Col span={8}>
          <div>
            <strong>Coordinates:</strong>
            <p>{`lat: ${stadium.coordinates.lat} lng: ${stadium.coordinates.lng}`}</p>
          </div>
        </Col>
        <Col span={8}>
          <div>
            <strong>Country:</strong>
            <p>{stadium.country}</p>
          </div>
        </Col>
        <Col span={8}>
          <div>
            <strong>City:</strong>
            <p>{stadium.city}</p>
          </div>
        </Col>
      </Row>

      <Row style={{ marginBottom: "20px" }}>
        <Col span={24}>
          <textarea
            rows={4}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #d9d9d9",
              resize: "none",
              outline: "none",
            }}
            placeholder="Enter your prompt here..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </Col>
      </Row>

      <Row justify="end" style={{ marginBottom: "20px", display: "flex", gap: "10px" }}>
        <Col>
          {rawData.length > 0 && (
            <Button onClick={handleViewCreatedData} type="default">
              Create in database
            </Button>
          )}
        </Col>
        <Col>
          <Button type="primary" onClick={handleViewFetchData} disabled={!prompt || loadingFetch}>
            View Created Data
          </Button>
        </Col>
      </Row>

      {rawData.length > 0 && (
        <Table
          dataSource={rawData}
          columns={columns}
          pagination={false}
          scroll={{ x: "100%" }}
        />
      )}
    </Modal>
  );
};

export default CreateAttractionModal;
