import React from "react";
import { NavLink } from "react-router-dom";
// import Image from "./../../images/img.PNG";
import { Layout } from "antd";
import { v1 as uuid } from "uuid";
import style from "./style.module.scss";
import Logo from "./../../images/app-logo.png";

const { Sider } = Layout;

class MenuLeft extends React.Component {
  state = {
    items: [
      // {
      //   name: "All Countries",
      //   navlink: "/",
      //   id: uuid(),
      // },
      // {
      //   name: "Countries With Borders",
      //   navlink: "/countriesWithBorders",
      //   id: uuid(),
      // },
      // {
      //   name: "Teams",
      //   navlink: "/teams",
      //   id: uuid(),
      // },
      {
        name: "Leagues",
        navlink: "/",
        id: uuid(),
      },
      {
        name: "Fixtures",
        navlink: "/fixtures",
        id: uuid(),
      },
    
      // {
      //   name: "Pubs Reviews",
      //   navlink: "/pubsReviews",
      //   id: uuid(),
      // },
      {
        name: "Stadiums",
        navlink: "/stadiumList",
        id: uuid(),
      },
      {
        name: "Rivalries list",
        navlink: "/rivalries",
        id: uuid(),
      },
      {
        name: "Attractions",
        navlink: "/attractions",
        id: uuid(),
      },
      {
        name: "Users",
        navlink: "/users",
        id: uuid(),
      },
      {
        name: "Attraction Types",
        navlink: "/attractionTypes",
        id: uuid(),
      },
         {
        name: "Attraction Reviews",
        navlink: "/attractionReviews",
        id: uuid(),
      },
      // {
      //   name: "Tickets",
      //   navlink: "/tickets",
      //   id: uuid(),
      // },
    ],
  };

  render() {
    return (
      <Sider className={style.air__menuLeft}>
        <div className={style.air__menuLeft__outer}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingTop: "1em",
            }}
          >
            <img src={Logo} alt="logo" style={{ width: "75%" }} />
          </div>

          <ul className={style.air__menuLeft__outer__firstList}>
            {this.state.items.map((item) => (
              <NavLink
                key={item.id}
                exact
                to={item.navlink}
                activeClassName={
                  style.air__menuLeft__outer__firstList__activeItem
                }
              >
                <li className={style.air__menuLeft__outer__firstList__listItem}>
                  <span
                    className={
                      style.air__menuLeft__outer__firstList__listItem__span
                    }
                  >
                    {item.name}
                  </span>
                </li>
              </NavLink>
            ))}
              <li className={style.air__menuLeft__outer__firstList__listItem} onClick={()=>{localStorage.removeItem('token');    window.location.href = "/";}}>
                  <span
                    className={
                      style.air__menuLeft__outer__firstList__listItem__span
                    }
                  >
                    logout
                  </span>
                </li>
          </ul>
        </div>
      </Sider>
    );
  }
}

export default MenuLeft;
